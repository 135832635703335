<template>
    <div class="styled-border mb-1">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: 'ComponentWithStyledBorder'
}
</script>
<style scoped>
.styled-border {
    border-style: solid;
    border-width: thin;
    border-color: rgb(96, 96, 96, 0.2);
}
</style>
